import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $('.leader-data-toggle').on('click', function(){
      let leaderArticle = $(this).closest('article');

      if (leaderArticle.hasClass('leader-article--closed')) {
        setTimeout(() => {
          leaderArticle.find('.leader-intro').show();
        }, 150)
        leaderArticle.css('background-color', '#2C6771');
        leaderArticle.css('background-image', 'unset');
      }
      else {

        leaderArticle.css('background-image', leaderArticle.data('cover-img'))
        leaderArticle.css('background-color', 'none');
        leaderArticle.find('.leader-intro').hide();
      }

      $(this).toggle();
      $(this).siblings('.leader-data-toggle').toggle();
      leaderArticle.find('.leader-contact').toggle();
      leaderArticle.toggleClass('leader-article--closed');
    })
  }
}
