import { Controller } from "@hotwired/stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
    let id = event.item.dataset.id;

    $.ajax({
      url: this.element.dataset.moveUrl.replace(':id', id),
      type: 'PUT',
      data: { 'order': event.newIndex + 1 }
    });
  }
}
