import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Pause video on modal close
    $('#timestone_video').on('close', function() {
      let contentWindow = $('#timestone_video iframe')[0].contentWindow;
      contentWindow.postMessage('{"method": "pause"}', '*');
    })
  }
}
