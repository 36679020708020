import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  connect() {
    window.Cookies = Cookies;

    if ($('#popup-modal').length) {
      let $popupModal = $('#popup-modal');
      let $popupId = $popupModal.data('popup-id');

      if (Cookies.get(`popup-${$popupId}`)) {
        return false;
      }
      else {
        let $popupDelayTime = $popupModal.data('delay-time');

        setTimeout(() => {
          Cookies.set(`popup-${$popupId}`, 'true', { expires: 1 })
          $popupModal[0].showModal();
        }, +$popupDelayTime * 1000);
      }
    }
  }
}
