import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $(".slick-carousel-timestone").on('change', "input[name='timestone-tab'][type='radio']", (e) => {
      if ($(e.currentTarget).is(':checked')) {
        let checkedValue = $(e.currentTarget).val();
        $('.timestone-tab-data:visible').fadeOut();
        setTimeout(function() {
          $(`.timestone-tab-data[data-tab-value='${checkedValue}']`).fadeIn();
        }, 400)
      }
    })

    // Custom tabs
    $('.tab-label input[type="radio"]').on('change', function(e) {
      if (e.target.checked) {
        let targetId = $(e.target).attr('id');
        $(e.target).closest('.tabs').children('.tab-contents').children('.tab-content').hide();
        $(e.target).closest('.tabs').find(`.tab-content[data-tab-content="${targetId}"]`).show();
      }
    })
  }
}
