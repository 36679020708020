import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.count();
  }

  count() {
    let moneyFormatter = new Intl.NumberFormat();
    function isOnScreen(element) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();

      var elemTop = element.offset().top;
      var elemBottom = elemTop + element.height();

      return elemTop <= docViewBottom;
    }

    function updateCount(counter, target, count, index, counterValues, speed) {
      const inc = target / speed;
      counterValues[index] = count + inc;
      if (count < target) {
        counter.innerText =
          target % 1 === 0
            ? moneyFormatter.format(Math.floor(counterValues[index]))
            : moneyFormatter.format(Math.round(counterValues[index] * 10) / 10);
      } else {
        counter.innerText = moneyFormatter.format(target);
      }
    }

    window.addEventListener("scroll", function () {
      $(".number-counter-section").each(function () {
        if (isOnScreen($(this))) {
          if ($(this).hasClass("counter-activated")) {
            window.removeEventListener("scroll", null);
            return;
          }

          $(this).addClass("counter-activated");

          let counterValues = [];
          let speed = 500;
          let numberElements = $(this).find(".number-counter");
          numberElements.each(function (index, e) {
            counterValues.push(0);
            let interval = setInterval(() => {
              const target = +$(this).data("value");
              const count = counterValues[index];
              if (target !== count) {
                updateCount(
                  $(this)[0],
                  target,
                  count,
                  index,
                  counterValues,
                  speed,
                );
              } else {
                clearInterval(interval);
              }
            }, 1);
          });
        }
      });
    });
  }
}
