import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $('.wrapper.file.image input[type="file"]').on('change', function() {
      let file = this.files[0];
      $(this).closest('.wrapper').find('.image-preview img').attr('src', URL.createObjectURL(file))
    })
  }
}
