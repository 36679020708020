import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $('.value-content').on('click', function(e) {
      let $valueContent = $(e.currentTarget)

      if ($valueContent.hasClass('active')) {
        return false;
      }
      else {
        $('.value-content').removeClass('active');
        $valueContent.addClass('active');

        let $valueIndex = $valueContent.data('index');
        $('.value-description').addClass('hidden');
        $(`.value-description[data-index="${$valueIndex}"`).removeClass('hidden')
      }
    })
  }
}
