import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $("input[name='event-month']").on('change', (e) => {
      if (e.target.checked) {
        let eventBlock = $(".event-block[data-month='" + $(e.target).val() + "']");

        $([document.documentElement, document.body]).animate({
          scrollTop: eventBlock.first().offset().top - 330
        }, 500);
      }
    })

    $("input[name='event-day']").on('change', (e) => {
      if (e.target.checked) {
        let eventBlock = $(".event-block[data-day='" + $(e.target).val() + "']");

        $([document.documentElement, document.body]).animate({
          scrollTop: eventBlock.first().offset().top - 330
        }, 500);
      }
    })

    let checkedMonthInput = $("input[name='event-month']:checked");
    if (checkedMonthInput.length > 0 && checkedMonthInput.is(':not(:disabled)')) {
      setTimeout(() => {
        checkedMonthInput.trigger('change');
      }, 500);
    } else {
      setTimeout(() => {
        $("input[name='event-month']:not(:disabled)").first().trigger('click');
      }, 500);
    }

    let checkedDayInput = $("input[name='event-day']:checked");
    if (checkedDayInput.length > 0 && checkedDayInput.is(':not(:disabled)')) {
      setTimeout(() => {
        checkedDayInput.trigger('change');
      }, 500);
    } else {
      setTimeout(() => {
        $("input[name='event-day']:not(:disabled)").first().trigger('click');
      }, 500);
    }
  }
}
