import { Controller } from "@hotwired/stimulus";
import 'slick-carousel';

export default class extends Controller {
  connect() {
    $('.slick-carousel-1').not('.slick-initialized').slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: true,
      prevArrow: false,
      nextArrow: false,
      appendDots: $('.slick-carousel-dots-1'),
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    });

    $('.slick-carousel-2').not('.slick-initialized').slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: true,
      prevArrow: false,
      nextArrow: false,
      appendDots: $('.slick-carousel-dots-2'),
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    });

    $('.slick-carousel-dots button').html('');

    // Set position of slick carousel when changing tabs
    $("input[name='my_tabs_1']").on('change', function() {
      if (this.checked) $(this).siblings('.daisyui-tab-content').find('.slick-carousel').slick('setPosition');
    })

    $('.slick-carousel-event').not('.slick-initialized').slick({
      infinite: true,
      fade: true,
      draggable: false,
      autoplay: true,
      autoplaySpeed: 4500,
      prevArrow: $('.slick-carousel-event .slick-carousel-prev'),
      nextArrow: $('.slick-carousel-event .slick-carousel-next'),
      pauseOnFocus: false,
      pauseOnHover: false,
    })

    $('.slick-carousel-event .slick-carousel-autoplay-toggle').on('click', function() {
      if ($(this).hasClass('autoplay-toggle-pause')) {
        $('.slick-carousel-event .autoplay-toggle-pause').addClass('hidden');
        $('.slick-carousel-event .autoplay-toggle-play').removeClass('hidden');
        $('.slick-carousel-event .event-info').removeClass('event-play');
        $('.slick-carousel-event').slick('slickPause');
      } else {
        $('.slick-carousel-event .autoplay-toggle-pause').removeClass('hidden');
        $('.slick-carousel-event .autoplay-toggle-play').addClass('hidden');
        $('.slick-carousel-event .event-info').addClass('event-play');
        $(this).closest('.event-info').outerHeight();
        $('.slick-carousel-event').slick('slickPlay');
      }
    })

    // About us - Timestone section
    $('.slick-carousel-timestone').not('.slick-initialized').slick({
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: false,
      prevArrow: $('.timestone-prev'),
      nextArrow: $('.timestone-next'),
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 639,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
      ]
    })

    $('.slick-carousel-timestone').on('afterChange', function(event, slick, currentSlide) {
      $(".slick-slide.slick-current input[name='timestone-tab'").trigger('click');

      if (slick.currentSlide == 0) {
        $('.timestone-prev').addClass('pointer-events-none');
      }
      else {
        $('.timestone-prev').removeClass('pointer-events-none');
      }

      if (slick.currentSlide >= slick.slideCount - slick.options.slidesToShow) {
        $('.timestone-next').addClass('pointer-events-none');
      }
      else {
        $('.timestone-next').removeClass('pointer-events-none');
      }
    })

    // Event - Calendar section
    $('.slick-carousel-calendar').slick({
      initialSlide: 1,
      infinite: false,
      draggable: false,
      prevArrow: $('.slick-carousel-calendar-prev'),
      nextArrow: $('.slick-carousel-calendar-next'),
    })

    // Podcast banner carousel
    $('#podcast-banner-carousel').not('.slick-initialized').slick({
      prevArrow: false,
      nextArrow: false,
      dots: true,
      appendDots: $('.podcast-banner-carousel-controls')
    })

    $('.podcast-banner-carousel-controls button').html('');

    $('#podcast-banner-carousel').on('afterChange', function (event, slick, currentSlide) {
      let $categoryType = $('#podcast-banner-carousel .slick-active .category-type').data('category-type');
      let $categoryInput = $(`input#${$categoryType}-podcasts`);
      $categoryInput.trigger('click');

      let $podcastInput = $categoryInput.closest('.tabs').find(`input[name^="podcast-playlist-${$categoryType}"]:checked`)
      if ($($podcastInput).closest('.tabs').find(`.tab-content[data-tab-content="${$podcastInput.attr('id')}"] .podcasts`).is(':empty')) {
        $.ajax({
          url: '/podcast_pagy',
          dataType : 'script',
          data: {
            type: $podcastInput.data('type'),
            index: $podcastInput.data('index')
          }
        })
      }
    })

    // Enterprise training service - Review section
    $('#review-carousel').not('.slick-initialized').slick({
      centerMode: true,
      centerPadding: '260px',
      slidesToShow: 2,
      prevArrow: $('.review-carousel-prev'),
      nextArrow: $('.review-carousel-next'),
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            centerPadding: '50px',
            slidesToShow: 2
          }
        },
        {
          breakpoint: 641,
          settings: {
            centerPadding: '50px',
            slidesToShow: 1
          }
        },
      ]
    })

    // Coaching service - Service section
    $('#services-carousel').not('.slick-initialized').slick({
      slidesToScroll: 1,
      prevArrow: $('.services-carousel-prev'),
      nextArrow: $('.services-carousel-next'),
    })
  }
}
