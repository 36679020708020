import { Controller } from "@hotwired/stimulus";
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import { Bold, Italic, Strikethrough, Underline } from '@ckeditor/ckeditor5-basic-styles';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Link, LinkImage } from '@ckeditor/ckeditor5-link';
import { List } from '@ckeditor/ckeditor5-list';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { AutoImage } from '@ckeditor/ckeditor5-image';
import { FileRepository } from '@ckeditor/ckeditor5-upload';
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed';
import { Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageInsert, ImageUpload } from '@ckeditor/ckeditor5-image';

import UploadAdapter from './ckeditor/upload_adapter.js';

function CustomUploadAdapterPlugin( editor ) {
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
    return new UploadAdapter( loader );
  };
}

export default class extends Controller {
  connect() {
    const ckeditorElements = document.querySelectorAll( '.ckeditor' );

    ckeditorElements.forEach( function(el) {
      ClassicEditor
      .create( el, {
        plugins: [
          Essentials,
          Autoformat,
          Bold,
          Italic,
          Strikethrough,
          Underline,
          BlockQuote,
          Heading,
          Link,
          List,
          Paragraph,
          Alignment,
          AutoImage,
          FileRepository,
          MediaEmbed,
          Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, LinkImage, ImageInsert, ImageUpload
        ],
        toolbar: [
          'undo', 'redo', '|',
          'heading', '|',
          'bold', 'italic', 'strikethrough', 'underline', '|',
          'bulletedList', 'numberedList', '|',
          'alignment', '|',
          'link', 'insertImage', 'blockQuote', 'mediaEmbed'
        ],
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
        },
        extraPlugins: [ CustomUploadAdapterPlugin ],
        image: {
          toolbar: [
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'linkImage'
          ],
          insert: {
              // This is the default configuration, you do not need to provide
              // this configuration key if the list content and order reflects your needs.
              integrations: [ 'upload', 'url' ],
              type: 'auto'
          }
        },
        mediaEmbed: {previewsInData: true}
      } )
    } )
  }
}
