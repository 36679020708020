import "@/stylesheets/index.scss";
import "@hotwired/turbo-rails";
import "@/controllers";
import "@/controllers/shared";
import { Turbo } from '@hotwired/turbo-rails'
import Rails  from '@rails/ujs';

Turbo.session.drive = false

Rails.start();
