import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $('.faq-content').on('click', function(e) {
      let $faqContent = $(e.currentTarget);

      if ($faqContent.hasClass('active')) {
        $faqContent.removeClass('active');
        $faqContent.find('.faq-answer').slideUp();
      }
      else {
        $faqContent.addClass('active');
        $faqContent.find('.faq-answer').slideDown();
      }
      $faqContent.find('.faq-question').toggleClass('md:min-h-[60px]')
    })
  }
}
