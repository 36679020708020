import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $('.event-block').on('mouseenter', (e) => {
      let color = $(e.currentTarget).data('color');
      $(e.currentTarget).css('background-color', color);
      $(e.currentTarget).find('.event-color-bar').css('background-color', 'white');
      $(e.currentTarget).find('.book-button').css('color', color);
    })

    $('.event-block').on('mouseleave', (e) => {
      let color = $(e.currentTarget).data('color');
      $(e.currentTarget).css('background-color', 'white');
      $(e.currentTarget).find('.event-color-bar').css('background-color', color);
      $(e.currentTarget).find('.book-button').css('color', 'white');
    })
  }
}
