import { Controller } from "@hotwired/stimulus";
import Pagy from './pagy/pagy-module.js';

export default class extends Controller {
  connect() {
    window.Pagy = Pagy

    $.ajax({
      url: '/podcast_pagy',
      dataType : 'script',
      data: {
        type: 'spotify',
        index: 'all'
      }
    })

    $('input[name^="podcast-playlist-"]').on('change', function() {
      let $inputId = $(this).attr('id');

      if ($(this).is(':checked') && $(this).closest('.tabs').find(`.tab-content[data-tab-content="${$inputId}"] .podcasts`).is(':empty')) {
        let $categoryType = $(this).attr('name').replace('podcast-playlist-', '')
        let $categoryIndex = $(this).data('index')

        $.ajax({
          url: '/podcast_pagy',
          dataType : 'script',
          data: {
            type: $categoryType,
            index: $categoryIndex
          }
        })
      }
    })
  }
}
