import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $('.coaching-type').on('click', function(e) {
      let $coachingType = $(e.currentTarget);

      if ($coachingType.hasClass('active')) {
        return false;
      }
      else {
        let $currentSection = $coachingType.closest('div[data-controller="coaching-type"]');
        $currentSection.find('.coaching-type').removeClass('active');
        $coachingType.addClass('active');

        let $coachTypeIndex = $coachingType.data('index');
        $currentSection.find('.coaching-type__info').addClass('hidden');
        $currentSection.find(`.coaching-type__info[data-index="${$coachTypeIndex}"]`).removeClass('hidden');

        $([document.documentElement, document.body]).animate({
          scrollTop: $currentSection.find('.coaching-type__container').first().offset().top - 86
        }, 300)
      }
    })
  }
}
